import React, { useState } from 'react'
import { useLocation } from 'react-router';
import { withRouter } from 'react-router'
import { IsMobileWidth, IsTabletWidth, redirectToHomePage } from 'components/common/util/util';
import TopNavigationPanelPopover from '../top.navigation.panel/components/top.navigation.panel.popover/top.navigation.panel.popover';
import LanguageDropdown from '../language.dropdown/language.dropdown';

const AppTopMenu = (props) => {
    const { name, label, status, hideNavigationButtons, hideCloseButton, transparent } = props
    const tradeTypeId = window.TRADE_TYPE_ID; // getQueryStringValue("trade_type_id");
    let location = useLocation();
    const mobileWidth = IsMobileWidth()
    const tabletWidth = IsTabletWidth()
    const [isNavigationPopoverOpen, setIsNavigationPopoverOpen] = useState(null);

    const showIsNavigationPopover = (event) => {
        setIsNavigationPopoverOpen(event.currentTarget);
    };

    const hideIsNavigationPopover = () => {
        setIsNavigationPopoverOpen(null);
    };

    const redirect = (url) => {
        if (url === '/products') {
            redirectToHomePage(props, tradeTypeId)
        }
        else {
            props.history.push(`${url}?trade_type_id=${tradeTypeId}`)
        }
        hideIsNavigationPopover();
    }

    return (
        <>
            <div className='position-sticky' style={{ backgroundColor: "#1558CE", padding: "100px 0 50px 0", borderBottomRightRadius: "50%", top:"0" }}>
                <div className='position-absolute' style={{ top: "5%", left: "5%" }}>
                    <LanguageDropdown />
                </div>
                <img
                    src={process.env.PUBLIC_URL + '/assets/images/nokia-arena-primary-logo-v1.png'}
                    className="img-fluid object-fit-cover d-flex m-auto w-60"
                />
                <div onClick={showIsNavigationPopover} className='position-absolute' style={{ top: "5%", right: "5%" }}>
                    <img
                        src='/assets/icons/hamburger-menu-icon-v1.png'
                        className="img-fluid object-fit-cover"
                    />
                </div>
            </div>
            {isNavigationPopoverOpen ?
                <TopNavigationPanelPopover // Currently only for mobile || tablet view
                    isNavigationPopoverOpen={isNavigationPopoverOpen}
                    showIsNavigationPopover={showIsNavigationPopover}
                    hideIsNavigationPopover={hideIsNavigationPopover}
                    redirect={redirect}
                    location={location}
                    {...props}
                /> : null
            }
        </>
    )
}

export default withRouter(AppTopMenu)
